import { axiosInstance } from "../../../../network/interceptors";
// import {handleAssessmentsListToFitInCarouselArr} from '../../../../utils/shared';
import store from "../../../index.js";
export const actions = {
  getProfileDataRequest({ dispatch }) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/users/profile")
        .then((res) => {
          dispatch("getProfileDataResponse", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },

  getProjectTypesRequest({ dispatch }) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/staticContent/projectTypes")
        .then((res) => {
          dispatch("getProjectTypesResponse", res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => reject(error));
    });
  },

  AddProjectRequest({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/projects/create", data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  deleteProjectRequest({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/api/projects/${id}`)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  getProjectDetailsRequest({ dispatch }, id) {
    console.log(dispatch);
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/projects/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
  updateProfileLanguages({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`api/users/languages`, data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },
  updateProjectDetailsRequest({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/api/projects/${data.id}`, data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  uploadProfileCV({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`api/users/importCv`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  uploadProfileImage({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`api/users/uploadImage`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  updateProfileSkills({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`api/users/skills`, data)
        .then(() => {
          window.location.reload();
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  // eslint-disable-next-line no-unused-vars
  subscription({ dispatch }, subscription_id) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`api/users/subscribe/${subscription_id}`)
        .then(() => {
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },
  // eslint-disable-next-line no-unused-vars
  unsubscribe({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`api/users/unsubscribe/`, payload)
        .then(() => {
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  updateProfileAvailability({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`api/users/availability`, data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  updateProfileSalary({ dispatch }, data) {
    // const {personalInfo, full_time_salary, part_time_salary} = data
    return new Promise((resolve, reject) => {
      axiosInstance
        // .put(`/api/users/personalInfoAndAccounts?salary=${full_time_salary}&part_time_salary=${part_time_salary}`, personalInfo)
        .put("api/users/salaries", data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  getRoleSkillsRequest({ dispatch }, role_id) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/staticContent/roles/${role_id}/skills`)
        .then((res) => {
          dispatch("getRoleSkillsResponse", res.data.data);
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  getExperiencDetailsRequest({ dispatch }, id) {
    console.log(dispatch);
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/experiences/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },

  addExperienceRequest({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/experiences", data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  updateEsperienceRequest({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/api/experiences/${data.id}`, data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  deleteExperienceRequest({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/api/experiences/${id}`)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  getCertificateDetailsRequest({ dispatch }, id) {
    console.log(dispatch);
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/certifications/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },

  getAssessmentsRequest({ dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch("shared/handleSeletonStatus", true, { root: true });

      // .get(`/api/assessments?perPage=1&page=${data.page}`)
      axiosInstance
        .get(`/api/skills-assessments`)
        .then((res) => {
          // let list = handleAssessmentsListToFitInCarouselArr(res.data, data.page - 1)
          dispatch("getAssessmentsResponse", res.data.data);
          dispatch("shared/handleSeletonStatus", false, { root: true });
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },

  getAssessmentInvitationRequest({ dispatch }, data) {
    console.log(dispatch);
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`/api/assessments/${data.AssessmentId}/invite`)
        .then((res) => {
          let takeAssessmentEle = document.getElementById(
            "assessmentInvitationLink"
          );
          takeAssessmentEle.href = res.data.data.link;
          takeAssessmentEle.click();
          let assessmentList = store.getters["talentProfile/getAssessments"];
          let assessmentListUpdated = assessmentList?.map((ele) => {
            if (ele.assessment.id === data.AssessmentId) {
              ele.assessment.link = res.data.data.link;
              return ele;
            } else {
              return ele;
            }
          });
          dispatch("getAssessmentsResponse", assessmentListUpdated);
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },

  addCertificateRequest({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/certifications", data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  updateCertificateRequest({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/api/certifications/${data.id}`, data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  deleteCertificateRequest({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/api/certifications/${id}`)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  getEducationDetailsRequest({ dispatch }, id) {
    console.log(dispatch);
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/educations/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },

  addEducationRequest({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/educations", data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  updateBioRequest({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/api/users/bio`, data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  updateEducationRequest({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/api/educations/${data.id}`, data)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  deleteEducationRequest({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(`/api/educations/${id}`)
        .then(() => {
          dispatch("getProfileDataRequest");
          resolve("Success");
        })
        .catch((error) => reject(error));
    });
  },

  getBasicInfoRequest() {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/users/basicInfo`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
  EditPersonalInfoRequest({ dispatch }, data) {
    console.log(dispatch);
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(`/api/users/personalInfoAndAccounts`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },

  getProjectTypesResponse({ commit }, data) {
    commit("get_project_types_respones", data);
  },
  getProfileDataResponse({ commit }, data) {
    commit("get_profile_data_respones", data);
  },

  getRoleSkillsResponse({ commit }, data) {
    commit("get_role_skills", data);
  },

  getAssessmentsResponse({ commit }, data) {
    commit("get_assessments", data);
  },
};
