const ClientSteps =
  localStorage.getItem("CRO_feature_flag") ===
  "ID3_sitewide_rearranging_screens"
    ? {
        ABOUT_PROJECT: "4",
        SELECT_SERVICE: "1",
        TECHNOLOGIES: "2",
        DETAILS: "3",
        BOOK_MEETING: "5",
        DONE: "6",
        PROFILE: "7",
      }
    : {
        ABOUT_PROJECT: "1",
        SELECT_SERVICE: "2",
        TECHNOLOGIES: "3",
        DETAILS: "4",
        BOOK_MEETING: "5",
        DONE: "6",
        PROFILE: "7",
      };

export default ClientSteps;
